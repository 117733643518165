import { Component, Inject, Watch, Vue, Prop } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfCategory from "@/vue/domain/category/df-category";
import DfContent from "@/vue/domain/content/df-content";
import DfStore from "@/vue/domain/store/df-store";
import viewportresize from "@/vue/directives/viewportresize";
import DfBrand from "@/vue/domain/brand/df-brand";
const DfSetComponent = () => import(/* webpackChunkName: 'df-set' */ "@/vue/components/df-set/df-set.vue");
const DfModalComponent = () => import(/* webpackChunkName: 'df-modal' */ "@/vue/components/df-modal/df-modal.vue");
const DfTabbedPanelComponent = () => import(/* webpackChunkName: 'df-card-product' */ "@/vue/components/df-tabbed-panel/df-tabbed-panel.vue");

@Component({
  components: {
    DfModal: DfModalComponent,
    DfTabbedPanel: DfTabbedPanelComponent,
    DfSet: DfSetComponent,
  },
  directives: {
    viewportresize,
  },
})
export default class DfSectionProductsFilterComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: null })
  promotion: DfPromotion;

  private productsQueryValueTemp: string = null;
  private initialized = false;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get productsQueryValue(): string {
    return this.$store.getters.productsQueryValue;
  }

  set productsQueryValue(productsQueryValue: string) {
    this.$store.dispatch("setProductsQueryValue", productsQueryValue);
  }

  get selectedCategory(): any {
    const category: DfContent = this.$store.getters.activeCategory;
    return category ? { code: category.alias, label: category.name } : null;
  }

  set selectedCategory(categoryOption: any) {
    const category: DfContent = categoryOption && categoryOption.code != "REMOVE_SELECTION" ? this.promotionContents.find((content: DfContent) => content.type.code === "CAT" && content.alias === categoryOption.code) : null;
    this.$store.dispatch("setActiveCategory", category);
  }

  get isSearchActive(): boolean {
    const rule01: boolean = this.selectedTags.length > 0;
    const rule02: boolean = this.productsQueryValue && this.productsQueryValue.length > 0;
    const rule03: boolean = this.selectedCategory != null;
    const rule04: boolean = this.customFiltersQuery.length > 0;
    return rule01 || rule02 || rule03 || rule04;
  }

  get selectedTags(): Array<DfCategory> {
    return this.$store.getters.activeTags;
  }

  set selectedTags(activedTags: Array<DfCategory>) {
    this.$store.dispatch("setActiveTags", activedTags);
  }

  get customFiltersQuery(): Array<string> {
    return this.$store.getters.customFiltersQuery;
  }

  set customFiltersQuery(customFilters: Array<string>) {
    this.$store.dispatch("setcustomFiltersQuery", customFilters);
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get promotionContents(): Array<DfContent> {
    return this.$store.getters.promotionContents;
  }

  get productPropertiesForSearch(): Array<string> {
    return Utils.getPropertyValues(this.brand, Utils.PROPERTY_PRODUCT_PROPERTIES_FOR_SEARCH, "STRING");
  }

  get tags(): Array<DfCategory> {
    const tagsContent: Array<DfContent> = this.promotionContents.filter((content: DfContent) => content.type.code === "TAG");
    const primaryTag: Array<DfCategory> = tagsContent.filter((tagContent: DfContent) => Utils.getPropertyValue(tagContent, Utils.PROPERTY_CONTENT_PRIMARY, "BOOLEAN")).sort((contentA: DfContent, contentB: DfContent) => contentB.priority - contentA.priority).map((tagContent: DfContent) => new DfCategory(tagContent.alias, tagContent.name));
    const secondaryTag: Array<DfCategory> = tagsContent.filter((tagContent: DfContent) => !Utils.getPropertyValue(tagContent, Utils.PROPERTY_CONTENT_PRIMARY, "BOOLEAN")).sort((contentA: DfContent, contentB: DfContent) => contentB.priority - contentA.priority).map((tagContent: DfContent) => new DfCategory(tagContent.alias, tagContent.name));

    let tags: Array<DfCategory> = [...primaryTag, ...secondaryTag];
    if (["emisfero-unicomm", "famila-unicomm", "mega-unicomm"].includes(this.brand.alias)) {
      tags = tags.sort((categoryA: DfCategory, categoryB: DfCategory) => {
        let indexA: number = Utils.TAG_SORT_UNICOMM.findIndex((tagName: string) => tagName === categoryA.name);
        let indexB: number = Utils.TAG_SORT_UNICOMM.findIndex((tagName: string) => tagName === categoryB.name);
        indexA = indexA < 0 ? 999 : indexA;
        indexB = indexB < 0 ? 999 : indexB;

        return indexA - indexB;
      });
    }

    return tags;

  }

  get categories(): Array<any> {
    const categories: Array<DfContent> = this.promotionContents.filter((content: DfContent) => content.type.code === "CAT");
    return [
      ...[{ code: "REMOVE_SELECTION", label: this.$t("df-application.category-filter.options.all", {}) }],
      ...categories.map((categoryContent: DfContent) => {
        return { code: categoryContent.alias, label: categoryContent.name };
      }),
    ];
  }

  @Watch("tags")
  private tagsChange() {
    this.viewportResize();
    this.initPromotionFilter();
  }

  @Watch("productsQueryValue")
  private productsQueryValueChanged(newValue: string, oldValue: string) {
    if ((newValue || null) !== (oldValue || null)) {
      if ((newValue || null) !== (<any>this.$route.query.search || null)) {
        const query: any = Object.assign({}, this.$route.query, { search: newValue || undefined });
        this.$router.replace({ query: query });
      }
    }
  }

  @Watch("productsQueryValueTemp")
  private productsQueryValueTempChanged(newValue: string, oldValue: string) {
    if (!newValue && oldValue) {
      this.clearQuerySearch();
      this.startSearch();
    }
  }

  @Watch("selectedTags")
  private selectedTagsChanged(newValue: any, oldValue: any) {
    const newTags: Array<string> = newValue.map((tag: DfCategory) => tag.identifier);
    const oldTags: Array<string> = Array.isArray(this.$route.query.tags || []) ? <any>this.$route.query.tags || [] : [this.$route.query.tags];
    const newEntryTags: Array<string> = newTags.filter((tag: any) => !oldTags.includes(tag));
    const removedEntryTags: Array<string> = oldTags.filter((tag: any) => !newTags.includes(tag));
    if (newEntryTags.length || newTags.length !== oldTags.length || newValue.length !== oldValue.length) {
      newEntryTags.forEach((tag: any) => Utils.setAnaliticsTraker({ event: "click_tag", store: this.currentStore.name, promo_name: this.currentPromotion.description, tag: tag }));
      if (newEntryTags.length || removedEntryTags.length) {
        const query: any = Object.assign({}, this.$route.query, { tags: newTags });
        this.$router.replace({ query: query });
      }
    }
  }

  @Watch("selectedCategory")
  private selectedCategoryChanged(newValue: any, oldValue: any) {
    if (newValue !== oldValue) {
      const newCategory: string = newValue ? newValue.code : null;
      const oldCategory: string = <any>this.$route.query.category || null;
      if (newCategory !== oldCategory) {
        const query: any = Object.assign({}, this.$route.query, { category: newCategory });
        this.$router.replace({ query: query });
      }
    }
  }

  @Watch("customFiltersQuery")
  private customFiltersQueryChanged(newValue: Array<string>, oldValue: Array<string>) {
    if ((newValue || []).join("") !== (oldValue || []).join("")) {
      const query: any = Object.assign({}, this.$route.query, { filter: newValue });
      this.$router.replace({ query: query });
    }
  }

  @Watch("$route", { immediate: true })
  private showFilterMenuChanged() {
    const tagsQuery: Array<string> = Array.isArray(this.$route.query.tags || []) ? <any>this.$route.query.tags || [] : [this.$route.query.tags];
    this.initialized = this.initialized && tagsQuery.length === this.selectedTags.length;
    this.initPromotionFilter();
  }

  private updateSelectedTags(categories: Array<any>) {
    this.selectedTags = categories.map((category: any) => new DfCategory(category.code, category.name));
    this.startSearch();
  }

  private initPromotionFilter() {
    if (this.currentPromotion && this.promotionContents.length && !this.initialized) {
      const tagsQuery: Array<string> = Array.isArray(this.$route.query.tags || []) ? <any>this.$route.query.tags || [] : [this.$route.query.tags];
      this.selectedTags = this.tags.filter((tag: DfCategory) => tagsQuery.includes(tag.identifier)).map((tag: DfCategory) => new DfCategory(tag.identifier, tag.name));

      const categoryQuery: string = <any>this.$route.query.category || null;
      this.selectedCategory = this.tags
        .filter((category: DfCategory) => category.identifier === categoryQuery)
        .map((category: DfCategory) => {
          return { code: category.identifier, label: category.name };
        });

      this.productsQueryValueTemp = <any>this.$route.query.search || null;
      this.productsQueryValue = <any>this.$route.query.search || undefined;

      this.customFiltersQuery = Object.keys(this.$route.query)
        .filter((queryKey: any) => queryKey.startsWith("filters["))
        .map((queryKey: any) => `${queryKey}=${this.$route.query[queryKey]}`);

      if (!this.$route.params.skipInitFilter) {
        this.$root.$emit("loadProductsPage", null);
        (<any>document.activeElement).blur();
      }

      this.initialized = true;
    }
  }

  private clearQuerySearch() {
    this.productsQueryValueTemp = null;
    this.productsQueryValue = null;
  }

  private startSearch() {
    // TODO: aspettare che manuel fixi la ricerca
    if (this.productPropertiesForSearch.length === 0) {
      this.productsQueryValue = this.productsQueryValueTemp;
    }
    this.customFiltersQuery = [];
    if (this.productsQueryValueTemp) {
      this.productPropertiesForSearch.forEach((filterName: string, index: number) => {
        this.customFiltersQuery = [...this.customFiltersQuery, ...[`filters[${index}].propertyCode=${filterName}`, `filters[${index}].string.type=CONTAINS`, `filters[${index}].string.value=${this.productsQueryValueTemp}`]];
      });

      Utils.setAnaliticsTraker({
        event: "search",
        query: this.productsQueryValueTemp,
        origin: "digital_flyer",
      });
    }

    this.$root.$emit("loadProductsPage", null);
    (<any>document.activeElement).blur();
  }

  private closeFiltersModal(modalId: string) {
    this.$root.$emit("modules-modal.close", modalId);
  }

  private openFiltersModal(modalId: string) {
    this.$root.$emit("modules-modal.open", modalId);
  }

  private resetFilters(id: string, closePanel: boolean) {
    this.updateSelectedTags([]);
    if (closePanel) {
      this.closeFiltersPanel(id);
      this.closeFiltersModal(id);
    }
  }

  private openFiltersPanel(panelId: string) {
    this.$root.$emit("close-all-panel");
    this.$root.$emit("open-panel", panelId);
  }

  private closeFiltersPanel(panelId: string) {
    this.$root.$emit("close-all-panel");
    this.$root.$emit("close-panel", panelId);
  }

  private viewportResize() {
    this.$nextTick(() => {
      if (this.$refs["tags-set"]) {
        const tagsSetWidth: number = (<any>this.$refs["tags-set"]).$el.scrollWidth;
        const tagsSetParentWidth: number = (<any>this.$refs["tags-set"]).$el.parentNode.scrollWidth;
        if (tagsSetWidth >= tagsSetParentWidth) {
          (<any>this.$refs["tags-button"]).parentNode.classList.remove("df-invisible");
        } else {
          (<any>this.$refs["tags-button"]).parentNode.classList.add("df-invisible");
        }
      }
    });
  }
}
